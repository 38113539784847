import React, { useMemo } from "react";
import {
  TableStyle,
  Wrapper,
} from "./styled";
import { Tag, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import actions from "Store/Actions";

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined, StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";
import { Notification } from "Components";
import { SUBJECT_NAME } from "Utils/data-default";
import { useState } from "react";
import ModalEditSubject from "../ModalEditSubject";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const { moveCourse, updateSortSubject } = actions;

function TableResult({
  dataSource,
  columns,
  currentPage,
  total = 0,
  selected,
  pageSizeOptions,
  pageSize = 20,
  pagination = true,
  breadcrumb,
  action,
  createText,
  orderText,
  onChange,
  onCreate,
  onOrder,
  onDelete,
  height = "auto",
  titleTable,
  loading,
  setSelectedRowKeys,
  setSelectedDataDelete,
  selectedRowKeys,
  onToggleEdit,
  setDataRow,
  listMonth,
  numberMont,
  onToggleEditClh,
  ...rest
}) {
  const {
    currentStudentInformation,
  } = useSelector((state) => state.student);
  const [t] = useTranslation('student');
  const dispatch = useDispatch();
  const [isShowFullTable, setIsShowFullTable] = useState(true)
  const [isShowModalSubject, setIsShowModalSubject] = useState(false)
  const [subjectList] = useState(['英語','数学','国語','理科','社会']);
  useEffect(() => {
    if (currentStudentInformation.hasOwnProperty('sortSubject') &&  !currentStudentInformation.sortSubject) {
      const newData = dataSource.sort((a, b) => {
        return subjectList.indexOf(a.科目) - subjectList.indexOf(b.科目)
      })

      dispatch(
        updateSortSubject({
          data: newData
        })
      );
      }
  },[currentStudentInformation, dataSource, dispatch, subjectList])
  const sumTotalHalfWeek = (timeTotal) => {
    const sumOfCourse = dataSource.reduce((total, current) => {
      return total + (Number(current?.TIME_UNIT[timeTotal]?.unit) ? Number(current?.TIME_UNIT[timeTotal]?.unit) : 0)
    }, 0);
    return sumOfCourse
  }
  function getTimeUnit(record, value) {
    if (record?.["映・添"] === "添削") {
      return "T";
    }
    return value;
  }
  const hideColumns = (columns, keysToHide) => {
    return columns.map(column => {
      if (column.children) {
        column.children = hideColumns(column.children, keysToHide);
      }
      return {
        ...column,
        children: column.children ? column.children.filter(child => !keysToHide.includes(child.key)) : undefined
      };
    }).filter(column => !keysToHide.includes(column.key));
  };
  const handleOnOffData = async () => {
    setIsShowFullTable(pre => !pre)
  }
  const handleSubject = () => {
    setIsShowModalSubject(true)
  }
  const totalNumberOfPeriods = useMemo(() => {
    let sumTimeUnit = 0
    dataSource.map(item => {
      if (item['映・添'] === '映像') {
        const valueTimeUnit = Object.values(item['TIME_UNIT']).map(item => item.unit)
        sumTimeUnit +=  valueTimeUnit.reduce((accumulator, currentValue)=> Number(accumulator) + Number(currentValue) ,0 )
      }
      return sumTimeUnit;
    })
    return sumTimeUnit
  },[dataSource])
  const totalTimeOfCourse = (record) => {
    const valueTimeUnit = Object.values(record['TIME_UNIT']).map(item => item.unit)
    if (record['映・添'] === '映像') {
      return  valueTimeUnit.reduce((accumulator, currentValue)=> Number(accumulator) + Number(currentValue) ,0 )
    }
    return 0
  }
  const COLUMN_DATA_RESULT_LESSON_TEST = [
    {
      title: t("scheduled_course"),
      key: "受講予定講座",
      dataIndex: "受講予定講座",
      className: "scheduled_course_1",
      children: [
        {
          title: <div className="cursor-pointer" onClick={handleOnOffData}>{isShowFullTable ?
            <Tooltip title={t("tooltip_show_hide_table")}>
              <StepBackwardOutlined className="icon__hide-data" />
            </Tooltip>
            :
            <Tooltip title={t("tooltip_show_hide_table")}>
              <StepForwardOutlined className="icon__hide-data" />
            </Tooltip>
            }</div>,
          key: 'sort',
          width: 39,
          align: "center",
          display: "none",
          className:"on-off-table",
        },
        {
          title: <Tooltip className="cursor-pointer" title={t("tooltip_move_subject")} onClick={handleSubject}>
            {t("subject")}
             </Tooltip> ,
          dataIndex: "科目",
          key: "科目",
          align: "center",
          width: 50,
          // render: (text, record, index) => (
          //   <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
          //     <span>{text}</span>
          //     {selectedRowKeys.includes(record[`番号`]) && selectedRowKeys.length === 1 && <MoveBtnGroup text={text} record={record} index={index} dataSource={dataSource} />}
          //   </div>
          // ),
          render: (text, record, index) => (
            <div className={`
            ${record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"} 
            bg-${SUBJECT_NAME?.[record?.["科目"]]}
            `}
            >
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("film_attachment"),
          dataIndex: "映・添",
          key: "映・添",
          align: "center",
          width: 60,
          render: (text, record, index) => (
            <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("content"),
          dataIndex: "内容",
          key: "内容",
          align: "center",
          width: 50,
          render: (text, record, index) => (
            <div className={record?.["映・添"] === "添削" ? "supplementary" : "not_supplement"}>
              <span>{text}</span>
            </div>
          ),
        },
        {
          title: t("course_name"),
          dataIndex: "講座名",
          key: "講座名",
          align: "center",
          width: 200,
          className: "cell__course_name",
          render: (tags, record, index) => (
            <div
              className={record?.["映・添"] === "添削" ? "supplementary course_name  cursor-pointer" : "not_supplement course_name  cursor-pointer"}
              style={{
                textAlign: "left",
              }}
              onClick={(e) => {
                // if (record?.["映・添"] !== "添削") {
                  setDataRow(record)
                  onToggleEdit()
                // }
              }}
            >
              <Tooltip placement="top" title={tags} >
                {tags}
              </Tooltip>
            </div>
          )
        },
        {
          title: t("answer_correction"),
          dataIndex: "答案添削",
          key: "答案添削",
          align: "center",
          width: 75,
          render: (tags,record) => (
              record?.["映・添"] !== "添削" &&
                <Tag
                  style={{
                    width: "85%",
                    height: "22px",
                    background: `${tags === "なし" ? "#F5E7C2" : "#A1D2FB"}`,
                    textAlign: "center",
                    borderRadius: "2px",
                    border: "none",
                    margin: "0px",
                    paddingInline: "2px"
                  }}
                >
                  {tags}
                </Tag>
          ),
        },
        {
          title: t("status_course"),
          dataIndex: "スターテス",
          key: "スターテス",
          align: "center",
          width: 70,
          render: (tags) => (
            <Tag
              style={{
                width: "85%",
                height: "22px",
                background: `${tags === "未受講" ? "#F0A7AB" : tags === "受講済み" ? "#F5E7C2" : "#A1D2FB"}`,
                textAlign: "center",
                borderRadius: "2px",
                border: "none",
                margin: "0px",
                paddingInline: "2px"
              }}
            >
              {tags}
            </Tag>
          ),
        },
      ],
    },
    {
      title: '',
      key: "受講予定講座1",
      dataIndex: "受講予定講座",
      className: "scheduled_course_2",
      children: [
        {
          title: t("total_number_classes"),
          dataIndex: "総講数",
          key: "総講数",
          align: "center",
          width: 60,
          render: (value,record) => (
            <span >{ record?.["映・添"] === "添削" ? "" : value }</span>
          )
        },
        {
          title:  t("CL/H"),
          dataIndex: "CL / h",
          key: "CL / h",
          align: "center",
          width: 50,
          render: (value,record) => (
            <span className="cursor-pointer"
            onClick={(e) => {
                setDataRow(record)
                onToggleEditClh()
            }}
            >{record?.["映・添"] === "添削" ? "" : value}</span>
          ),
        },
      ],
    },
    {
      align: "center",
      width: 70,
      key:'required_time',
      title: () => {
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
              {t("week")}
              </div>
              <div style={{ width: "50%" }}>{t("month")}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: t("required_time"),
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 70,
          render: (text, record, index) => (
            <div className="show_delete">
              <span>{ record?.["映・添"] === "添削" ? "" : text } </span>
            </div>
          ),
        },
      ],
    },
    {
      align: "center",
      width: 50,
      title: () => {
        const totalMonth0 = sumTotalHalfWeek(listMonth[0])
        const totalMonth1 = sumTotalHalfWeek(listMonth[1])
        const totalMonth2 = sumTotalHalfWeek(listMonth[2])
        const totalMonth3 = sumTotalHalfWeek(listMonth[3])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[0]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth0 + totalMonth1 + totalMonth2 + totalMonth3) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth0 + totalMonth1 + totalMonth2 + totalMonth3))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={async (e) => {
                  !!record?.TIME_UNIT[`${listMonth[0]}`]?.unit && 
                  await setDataRow(record)
                  await onToggleEdit(onToggleEdit(listMonth[0]))
                }}
                className={!!record?.TIME_UNIT[`${listMonth[0]}`]?.unit ? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[0]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[0]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[1]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[1])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[1]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[1]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[1]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[2]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[2])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[2]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[2]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[2]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[3]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[3])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[3]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[3]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[3]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      width: 50,
      title: () => {
        const totalMonth4 = sumTotalHalfWeek(listMonth[4])
        const totalMonth5 = sumTotalHalfWeek(listMonth[5])
        const totalMonth6 = sumTotalHalfWeek(listMonth[6])
        const totalMonth7 = sumTotalHalfWeek(listMonth[7])

        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[1]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth4 + totalMonth5 + totalMonth6 + totalMonth7) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth4 + totalMonth5 + totalMonth6 + totalMonth7))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[4]}`]?.unit && 
                    setDataRow(record)
                  onToggleEdit(listMonth[4])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[4]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[4]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[4]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[5]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[5])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[5]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[5]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[5]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[6]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[6])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[6]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[6]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[6]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[7]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[7])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[7]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[7]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[7]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      width: 50,
      title: () => {
        const totalMonth8 = sumTotalHalfWeek(listMonth[8])
        const totalMonth9 = sumTotalHalfWeek(listMonth[9])
        const totalMonth10 = sumTotalHalfWeek(listMonth[10])
        const totalMonth11 = sumTotalHalfWeek(listMonth[11])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[2]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth8 + totalMonth9 + totalMonth10 + totalMonth11) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth8 + totalMonth9 + totalMonth10 + totalMonth11))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[8]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[8])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[8]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[8]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[8]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[9]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[9])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[9]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[9]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[9]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[10]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[10])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[10]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[10]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[10]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[11]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[11])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[11]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[11]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[11]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      width: 50,
      title: () => {
        const totalMonth12 = sumTotalHalfWeek(listMonth[12])
        const totalMonth13 = sumTotalHalfWeek(listMonth[13])
        const totalMonth14 = sumTotalHalfWeek(listMonth[14])
        const totalMonth15 = sumTotalHalfWeek(listMonth[15])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[3]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth12 + totalMonth13 + totalMonth14 + totalMonth15) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth12 + totalMonth13 + totalMonth14 + totalMonth15))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[12]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[12])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[12]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[12]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[12]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[13]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[13])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[13]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[13]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[13]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[14]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[14])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[14]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[14]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[14]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[15]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[15])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[15]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[15]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[15]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth16 = sumTotalHalfWeek(listMonth[16])
        const totalMonth17 = sumTotalHalfWeek(listMonth[17])
        const totalMonth18 = sumTotalHalfWeek(listMonth[18])
        const totalMonth19 = sumTotalHalfWeek(listMonth[19])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[4]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth16 + totalMonth17 + totalMonth18 + totalMonth19) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth16 + totalMonth17 + totalMonth18 + totalMonth19))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[16]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[16])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[16]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[16]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[16]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[17]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[17])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[17]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[17]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[17]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[18]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[18])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[18]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[18]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[18]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[19]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[19])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[19]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[19]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[19]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth20 = sumTotalHalfWeek(listMonth[20])
        const totalMonth21 = sumTotalHalfWeek(listMonth[21])
        const totalMonth22 = sumTotalHalfWeek(listMonth[22])
        const totalMonth23 = sumTotalHalfWeek(listMonth[23])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[5]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth20 + totalMonth21 + totalMonth22 + totalMonth23) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth20 + totalMonth21 + totalMonth22 + totalMonth23))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[20]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[20])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[20]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[20]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[20]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[21]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[21])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[21]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[21]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[21]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[22]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[22])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[22]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[22]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[22]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[23]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[23])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[23]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[23]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[23]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth24 = sumTotalHalfWeek(listMonth[24])
        const totalMonth25 = sumTotalHalfWeek(listMonth[25])
        const totalMonth26 = sumTotalHalfWeek(listMonth[26])
        const totalMonth27 = sumTotalHalfWeek(listMonth[27])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[6]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth24 + totalMonth25 + totalMonth26 + totalMonth27) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth24 + totalMonth25 + totalMonth26 + totalMonth27))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[24]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[24])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[24]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[24]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[24]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[25]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[25])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[25]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[25]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[25]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[26]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[26])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[26]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[26]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[26]}`]?.unit)}
                  {/* {record?.TIME_UNIT[`${listMonth[26]}`]?.unit} */}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[27]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[27])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[27]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[27]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[27]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth28 = sumTotalHalfWeek(listMonth[28])
        const totalMonth29 = sumTotalHalfWeek(listMonth[29])
        const totalMonth30 = sumTotalHalfWeek(listMonth[30])
        const totalMonth31 = sumTotalHalfWeek(listMonth[31])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[7]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth28 + totalMonth29 + totalMonth30 + totalMonth31) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth28 + totalMonth29 + totalMonth30 + totalMonth31))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[28]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[28])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[28]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[28]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[28]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[29]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[29])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[29]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[29]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[29]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[30]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[30])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[30]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[30]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[30]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[31]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[31])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[31]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[31]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[31]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth32 = sumTotalHalfWeek(listMonth[32])
        const totalMonth33 = sumTotalHalfWeek(listMonth[33])
        const totalMonth34 = sumTotalHalfWeek(listMonth[34])
        const totalMonth35 = sumTotalHalfWeek(listMonth[35])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[8]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth32 + totalMonth33 + totalMonth34 + totalMonth35) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth32 + totalMonth33 + totalMonth34 + totalMonth35))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[32]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[32])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[32]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[32]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[32]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[33]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[33])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[33]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[33]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[33]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[34]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[34])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[34]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[34]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[34]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[35]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[35])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[35]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[35]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[35]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth36 = sumTotalHalfWeek(listMonth[36])
        const totalMonth37 = sumTotalHalfWeek(listMonth[37])
        const totalMonth38 = sumTotalHalfWeek(listMonth[38])
        const totalMonth39 = sumTotalHalfWeek(listMonth[39])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[9]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth36 + totalMonth37 + totalMonth38 + totalMonth39) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth36 + totalMonth37 + totalMonth38 + totalMonth39))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div className={!!record?.TIME_UNIT[`${listMonth[36]}`]?.unit? "calender_time" : ""}>
                <span 
                  onClick={(e) => {
                    !!record?.TIME_UNIT[`${listMonth[36]}`]?.unit && 
                    setDataRow(record)
                    onToggleEdit(listMonth[36])
                  }}
                >
                  {record?.TIME_UNIT[`${listMonth[36]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[36]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div className={!!record?.TIME_UNIT[`${listMonth[37]}`]?.unit? "calender_time" : ""}>
                <span 
                  onClick={(e) => {
                    !!record?.TIME_UNIT[`${listMonth[37]}`]?.unit && 
                    setDataRow(record)
                    onToggleEdit(listMonth[37])
                  }}
                >
                  {record?.TIME_UNIT[`${listMonth[37]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[37]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div className={!!record?.TIME_UNIT[`${listMonth[38]}`]?.unit? "calender_time" : ""}>
                <span 
                  onClick={(e) => {
                    !!record?.TIME_UNIT[`${listMonth[38]}`]?.unit && 
                    setDataRow(record)
                    onToggleEdit(listMonth[38])
                  }}
                >
                  {record?.TIME_UNIT[`${listMonth[38]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[38]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div className={!!record?.TIME_UNIT[`${listMonth[39]}`]?.unit? "calender_time" : ""}>
                <span 
                  onClick={(e) => {
                    !!record?.TIME_UNIT[`${listMonth[39]}`]?.unit && 
                    setDataRow(record)
                    onToggleEdit(listMonth[39])
                  }}
                >
                  {record?.TIME_UNIT[`${listMonth[39]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[39]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth40 = sumTotalHalfWeek(listMonth[40])
        const totalMonth41 = sumTotalHalfWeek(listMonth[41])
        const totalMonth42 = sumTotalHalfWeek(listMonth[42])
        const totalMonth43 = sumTotalHalfWeek(listMonth[43])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[10]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth40 + totalMonth41 + totalMonth42 + totalMonth43) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth40 + totalMonth41 + totalMonth42 + totalMonth43))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[40]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[40])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[40]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[40]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[40]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[41]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[41])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[41]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[41]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[41]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[42]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[42])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[42]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[42]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[42]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[43]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[43])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[43]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[43]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[43]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth44 = sumTotalHalfWeek(listMonth[44])
        const totalMonth45 = sumTotalHalfWeek(listMonth[45])
        const totalMonth46 = sumTotalHalfWeek(listMonth[46])
        const totalMonth47 = sumTotalHalfWeek(listMonth[47])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[11]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth44 + totalMonth45 + totalMonth46 + totalMonth47) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth44 + totalMonth45 + totalMonth46 + totalMonth47))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[44]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[44])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[44]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[44]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[44]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[45]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[45])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[45]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[45]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[45]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[46]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[46])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[46]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[46]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[46]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div  
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[47]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[47])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[47]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[47]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[47]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      title: () => {
        const totalMonth48 = sumTotalHalfWeek(listMonth[48])
        const totalMonth49 = sumTotalHalfWeek(listMonth[49])
        const totalMonth50 = sumTotalHalfWeek(listMonth[50])
        const totalMonth51 = sumTotalHalfWeek(listMonth[51])
        return (
          <div>
            <div style={{ borderBottom: "1px solid #f0f0f0" }}>{numberMont[12]}{t("month")}</div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div style={{ borderRight: "1px solid #f0f0f0", width: "50%" }}>
                {Math.round(Number((totalMonth48 + totalMonth49 + totalMonth50 + totalMonth51) / 4))}
              </div>
              <div style={{ width: "50%" }}>{Math.round(Number(totalMonth48 + totalMonth49 + totalMonth50 + totalMonth51))}</div>
            </div>
          </div>
        );
      },
      children: [
        {
          title: '1',
          dataIndex: "TIME_UNIT",
          key: "TIME_UNIT",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[48]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[48])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[48]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[48]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[48]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '2',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[49]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[49])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[49]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[49]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[49]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '3',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 8,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[50]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[50])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[50]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[50]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[50]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
        {
          title: '4',
          dataIndex: "必要時限",
          key: "必要時限",
          align: "center",
          width: 20,
          padding: 0,
          render: (text, record, index) => {
            return (
              <div 
                onClick={(e) => {
                  !!record?.TIME_UNIT[`${listMonth[51]}`]?.unit && 
                  setDataRow(record)
                  onToggleEdit(listMonth[51])
                }}
                className={!!record?.TIME_UNIT[`${listMonth[51]}`]?.unit? "calender_time" : ""}
              >
                <span>
                  {record?.TIME_UNIT[`${listMonth[51]}`]?.unit && getTimeUnit(record,record?.TIME_UNIT[`${listMonth[51]}`]?.unit)}
                </span>
              </div>
            )
          },
        },
      ],
    },
    {
      align: "center",
      width: 65,
      key: 'for_checking',
      className: 'total_NumberOfPeriods',
      title: totalNumberOfPeriods,
      children: [
        {
          title: t("for_checking"),
          className: 'title_for_checking',
          dataIndex: "チェック用",
          key: "チェック用",
          align: "center",
          width: 65,
          render: (text, record, index) => (
            <div className="sum__time">
              <span>{totalTimeOfCourse(record)}</span>
            </div>
          ),
        },
      ],
    },
  ];

  const newDataColumns = useMemo(() => {
    if (isShowFullTable) {
      return []
    }
    const keysToHide = ['required_time', '受講予定講座1', 'スターテス', '答案添削'];
    var columnsToIgnore = [...COLUMN_DATA_RESULT_LESSON_TEST];
    return hideColumns(columnsToIgnore, keysToHide)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [COLUMN_DATA_RESULT_LESSON_TEST, isShowFullTable]);

useEffect(() => {
    const slider = document.querySelector(".antd_test .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table tbody.ant-table-tbody");
    const sliderPrevent = document.querySelectorAll(".antd_test .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table tbody.ant-table-tbody td:nth-child(n+1):nth-child(-n+6)");
    const body = document.querySelector(".antd_test .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body");
    let isDown = false;
    let startX;
    let scrollLeft;
    slider?.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - body.offsetLeft;
      scrollLeft = body.scrollLeft;
    });
    slider?.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider?.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider?.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - body.offsetLeft;
      const walk = (x - startX) * 1;
      body.scrollLeft = scrollLeft - walk;
    });
    for (let i = 0; i < sliderPrevent.length; i++) {
      sliderPrevent[i]?.addEventListener("mousedown", (e) => {
        e.stopImmediatePropagation();
      });
    }
  }, [])
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }) => {
    const infoCourse = dataSource.find(item => item.key === active.id)
    const subjectName = infoCourse?.['科目']
    const minIndex = dataSource.findIndex(item => item['科目'] === subjectName);
    const maxIndex = dataSource.reduce((maxIndex, currentObj, currentIndex) => {
      if (currentObj['科目'] === subjectName && currentIndex > maxIndex) {
        return currentIndex;
      }
      return maxIndex;
    }, -1);
    
    if (over?.data?.current?.sortable?.index && active?.data?.current?.sortable?.index !== over?.data?.current?.sortable?.index ) {
      if (minIndex <= over?.data?.current?.sortable?.index && over?.data?.current?.sortable?.index <= maxIndex) {
        dispatch(
          moveCourse({
            fromIndex: active?.data?.current?.sortable?.index,
            toIndex: over?.data?.current?.sortable?.index,
          })
        );
      }
      else {
        Notification.error(t("message.move_course_fail"));
      }
    }
  };

  // const Row = (props) => {
  //   const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
  //     id: props['data-row-key'],
  //   });
  //   const style = {
  //     ...props.style,
  //     transform: CSS.Transform.toString(
  //       transform && {
  //         ...transform,
  //         scaleY: 1,
  //       },
  //     ),
  //     transition,
  //     cursor: 'move',
  //     ...(isDragging
  //       ? {
  //           position: 'relative',
  //           zIndex: 9999,
  //         }
  //       : {}),
  //   };
  //   return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  // };

  const Row = ({ children, ...props }) => {
    const {
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        },
      ),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            // zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style}>
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move',
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const renderBorderColor = (record) => {
    if (record?.['newCourse'] && record?.['newCourse'] === 'new') {
      return `border-${SUBJECT_NAME[record?.['科目']]}`
    } 
    return ''
  }
  return (
    <Wrapper height={height}>
      <div className="table-head">
        <div className="table-head-left">
          <div className="content">
            <strong className="title">
              <span>
                &nbsp;
                {titleTable}
              </span>
            </strong>
          </div>
        </div>
      </div>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <TableStyle
            className={`antd_test table-base-class ${isShowFullTable ? 'table__full' : 'table__not-full'}`}
            dataSource={dataSource}
            columns={isShowFullTable ? COLUMN_DATA_RESULT_LESSON_TEST : newDataColumns }
            pagination={false}
            onChange={onChange}
            bordered
            loading={loading}
            rowClassName={(record) => renderBorderColor(record) }
            // rowSelection={{ ...rowSelection }}
            locale={{ emptyText:t("no_data_course")}}
            // className={dataSource.length > 0 ? "" : "no_data"}
            scroll={dataSource.length === 0 ? { x: 'max-content' } : { x: 'max-content', y: 700 }}
            {...rest}
            components={{
              body: {
                row: Row,
              },
            }}
          />
        </SortableContext>
      </DndContext>
      {isShowModalSubject && <ModalEditSubject
        visibleModal={isShowModalSubject}
        onOk={() => setIsShowModalSubject(false)}
        onCancel={() => setIsShowModalSubject(false)}
        dataSource={dataSource}
      />}
    </Wrapper>
  );
}
export default TableResult;
